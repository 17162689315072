<template>
    <div class="card">
        <div class="card__front">
            <div class="card__first-line">
                <Field
                    v-model="form.number"
                    :error="formErrors.number"
                    valueWithoutMask
                    :mask="cardMask"
                    title="Номер карты"
                    dontShowErrorMsg
                    autofocus
                    placeholder="0000 0000 0000 0000"
                />
            </div>
            <div class="card__second-line">
                <!-- <Field
                    v-model.trim="cardName"
                    :error="formErrors.holder_name"
                    class="card__owner"
                    title="Владелец"
                    placeholder="VLADIMIR KIROV"
                    dontShowErrorMsg
                /> -->
                <Field
                    v-model="form.date"
                    :error="formErrors.date"
                    mask="##/##"
                    class="card__date"
                    title="Срок"
                    dontShowErrorMsg
                    autoTab="cvv"
                    placeholder="09/25"
                />
            </div>
        </div>
        <div class="card__back">
            <Field
                name="cvv"
                v-model="form.cvv"
                :error="formErrors.cvv"
                mask="###"
                class="card__cvv"
                title="CVV"
                type="password"
                dontShowErrorMsg
                placeholder="000"
            />
        </div>
        <div
            v-show="errors.length"
            class="card__errors"
        >
            <p
                v-for="error in errors"
                :key="error"
            >
                {{ error }}
            </p>
        </div>
    </div>
</template>

<script>
import './card.scss'

import { CARD_OWNER_EXP, MAX as MAX_VAL, MIN as MIN_VAL } from '@/const/validationValues'
import Validation from '@/ext/validation/Validation'
import { year as currentYear } from '@/helpers/date/currentDate'
import validation from '@/mixins/validation'
import CardInfo from 'card-info'

const ALLOW_CARD_YEARS = 999

const { REQUIRED, MIN, MAX, EXP, DATE, DATE_MORE, DATE_LESS, CARD } = Validation

// const TODAY = `01.${currentMonth}.${currentYear}`
// const MAX_CARD_DATE = `01.${currentMonth}.${currentYear + ALLOW_CARD_YEARS}`

const DEFAULT_MASK = '#### #### #### #### ##'
const FORM = {
    number: '',
    holder_name: 'CARD HOLDER',
    date: '',
    cvv: ''
}

export default {
    name: 'Card',
    mixins: [
        validation,
    ],
    data() {
        return {
            form: FORM,
            formRules: {
                number: [
                    REQUIRED,
                    [MIN, 13],
                    [MAX, 18],
                    CARD,
                ],
                date: [
                    REQUIRED,
                    DATE,
                ],
                holder_name: [
                    [MIN, MIN_VAL],
                    [MAX, MAX_VAL],
                    [EXP, CARD_OWNER_EXP],
                ],
                cvv: [
                    REQUIRED,
                    [MIN, 3],
                    [MAX, 3],
                ]
            },
            customErrors: {
                number: {
                    [MIN]: 'Номер карты сожержит не менее {{0}} цифр',
                    [MAX]: 'Номер карты сожержит не более {{0}} цифр',
                },
                holder_name: {
                    [EXP]: 'Только символы латинского алфавита',
                },
                date: {
                    [DATE]: 'Срок указан не полностью',
                    [DATE_MORE]: 'Карта просрочена',
                    [DATE_LESS]: `Мы принимаем карты сроком до ${ALLOW_CARD_YEARS} лет`,
                }
            },
            cardMask: DEFAULT_MASK,
            cardInfo: {},
            error: ''
        }
    },
    created() {
        this.getCardMask()
    },
    computed: {
        errors() {
            const {
                number,
                holder_name,
                date,
                cvv
            } = this.formErrors

            return [
                this.error,
                number,
                holder_name,
                date,
                cvv
            ].reduce((acc, item) => {
                if (item && !acc.includes(item)) {
                    acc.push(item);
                }

                return acc;
            }, [])
        },
        cardName: {
            get() {
                return this.form.holder_name.replace(/^ +| +$|( ) +/g,'$1')
            },
            set(v) {
                this.form.holder_name = v
            }
        }
    },
    watch: {
        'form.number'() {
            this.getCardMask()
        },
        'request'(v) {
            this.error = '';
            this.$emit('request', v)
        },
        'form.date'(date) {
            if (!date.length)
                return

            const [month] = date.split('/')

            if (month > 12 || (month.length === 2 && +month === 0)) {
                this.form.date = '12'
            }
        }
    },
    methods: {
        validationData(data) {
            let {
                date = ''
            } = data

            if (date.length) {
                let [month, year = ''] = date.split('/')

                let yearStart = +(String(currentYear).substr(0, 2))
                const yearEnd = +(String(currentYear).substr(2))
                if (yearEnd < yearStart && yearEnd - ALLOW_CARD_YEARS <= 0)
                    ++yearStart

                year = `${yearStart}${year}`
                date = `01.${month}.${year}`
            }

            return {
                ...this.form,
                date,
            }
        },
        getCardMask() {
            const { number } = this.form

            if (number.length > 6 && this.cardInfo.numberBlocks)
                return

            if (number.length < 6) {
                this.cardInfo = {}
                this.cardMask = DEFAULT_MASK
            } else if (!this.cardInfo.numberBlocks) {
                this.cardInfo = new CardInfo(number)
                this.cardMask = this.cardInfo.numberBlocks.map((num) => {
                    return '#'.repeat(num)
                }).join(' ')
            }
        }
    },
}
</script>